import { createContext, useContext, useEffect, useState } from "react"
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { SignIn } from "./SignIn.tsx"
import { Register } from "./Register.tsx"
import { Home } from "./Home.tsx"
import { GAHT } from "./GAHT/index.tsx"
import { Services } from "./Services/index.tsx"
import { AccountSettings } from "./Account.tsx"
import { useProfile, useSignedIn, useSubscription } from "../api.ts"
import { PaymentDialogProvider } from "./PaymentDialog.tsx"
import { FormDialogProvider } from "./FormDialog.tsx"
import { installationIcon, useInstallApp } from "../installApp.ts"
import { Temporal } from "@js-temporal/polyfill"
import { BookingDialogProvider } from "./Book.tsx"

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: error => {
			console.error(error)
		}
	}),
	mutationCache: new MutationCache({
		onError: error => {
			console.error(error)
		}
	})
})

type Page = 'home' | 'gaht' | 'gaht/renew-prescription' | 'services' | 'account'
type TopLevelPage = 'home' | 'gaht' | 'services' | 'account'

export const PageContext = createContext<Page>('home')
export const PageSetContext = createContext<(page: Page) => void>(() => {})
export const PageCloseContext = createContext<(() => void)>(() => {})

function _SignedInAppView() {
	useEffect(() => {
		document.documentElement.classList.remove('light-only')
	}, [])

	const page = useContext(PageContext)
	const setPage = useContext(PageSetContext)
	const installApp = useInstallApp()
	const bannerClosed = localStorage.getItem('app-banner-closed')
	const [_, setBannerJustClosed] = useState(false)

	const topLevelPage = page.split('/')[0] as TopLevelPage

	return <>
		{installApp && bannerClosed === null ? <>
			<div role="toolbar" className="app-install-banner">
				<img src={new URL('../icons/maskable.png?as=png&height=32', import.meta.url).toString()} height={32} style={{borderRadius: '8px'}}/>
				<p style={{flex: '1'}}>Imago app</p>
				<button className="low-prio" disabled={installApp.isPending} onClick={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					installApp.mutate()
				}} >Install</button>
				<button className="low-prio" disabled={installApp.isPending} onClick={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					localStorage.setItem('app-banner-closed', Temporal.Now.instant().toString())
					setBannerJustClosed(true)
				}}>Dismiss</button>
			</div>
		</> : <></>}
		<nav role="tablist">
			<button role="tab" aria-selected={topLevelPage === 'home' ? true : undefined} aria-controls="home" onClick={ev => {
				ev.stopPropagation()
				ev.preventDefault()
				setPage('home')
			}}>
				<span className="material-symbols-outlined">home</span>
				<span>Home</span>
			</button>
			<button role="tab" aria-selected={topLevelPage === 'gaht' ? true : undefined} aria-controls="gaht" onClick={ev => {
				ev.stopPropagation()
				ev.preventDefault()
				setPage('gaht')
			}}>
				<span className="material-symbols-outlined">medical_services</span>
				<span>GAHT</span>
			</button>
			<button role="tab" aria-selected={topLevelPage === 'services' ? true : undefined} aria-controls="services" onClick={ev => {
				ev.stopPropagation()
				ev.preventDefault()
				setPage('services')
			}}>
				<span className="material-symbols-outlined">call</span>
				<span>Services</span>
			</button>
			<div className="only-on-medium-plus" style={{flex: '1'}}></div>
			{installApp ? <>
				<button role="tab" disabled={installApp.isPending} onClick={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					installApp.mutate()
				}} className="only-on-medium-plus">
					<span className="material-symbols-outlined">{installationIcon()}</span>
					<span>Install app</span>
				</button>
			</> : <></>}
		</nav>
		<div id="tabs">
			<div id="home" role="tabpanel" hidden={topLevelPage !== 'home'}>
				<Home/>
			</div>
			<div id="gaht" role="tabpanel" hidden={topLevelPage !== 'gaht'} data-subpage-open={page.startsWith('gaht/') ? true : undefined}>
				<GAHT fullScreen={true}/>
			</div>
			<div id="services" role="tabpanel" hidden={topLevelPage !== 'services'}>
				<Services fullScreen={true}/>
			</div>
			<div id="account" role="tabpanel" hidden={topLevelPage !== 'account'}>
				<AccountSettings/>
			</div>
		</div>
	</>
}

function _SignedInApp() {
	const subscription = useSubscription()!
	const [page, setPage] = useState<Page>(!subscription.periodEnds ? 'gaht' : 'home')
	const topLevelPage = page.split('/')[0] as TopLevelPage
	const closePage = () => setPage(topLevelPage)

	return <>
		<PageContext.Provider value={page}>
			<PageSetContext.Provider value={setPage}>
				<PageCloseContext.Provider value={closePage}>
					<_SignedInAppView/>
				</PageCloseContext.Provider>
			</PageSetContext.Provider>
		</PageContext.Provider>
	</>
}

function _App() {
	const signedIn = useSignedIn()
	const profile = useProfile()

	return (
		signedIn === true && profile !== null ?
			<_SignedInApp/> :

		signedIn === true && profile === null ?
			<Register/> :

		signedIn === false ?
			<SignIn/> :

		<></>
	)
}

export function App() {
	return <>
		<QueryClientProvider client={queryClient}>
			<BookingDialogProvider><PaymentDialogProvider><FormDialogProvider>
				<_App/>
			</FormDialogProvider></PaymentDialogProvider></BookingDialogProvider>
		</QueryClientProvider>
	</>
}
