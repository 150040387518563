const GDrive_FileID__Brand: unique symbol = Symbol()
export type GDrive_FileID = string & {[GDrive_FileID__Brand]: never}

const GDrive_FolderID__Brand: unique symbol = Symbol()
export type GDrive_FolderID = GDrive_FileID & {[GDrive_FolderID__Brand]: never}

const GDrive_DocumentID__Brand: unique symbol = Symbol()
export type GDrive_DocumentID = GDrive_FileID & {[GDrive_DocumentID__Brand]: never}

import { Google_Auth } from './_base.ts'
import { CalendarV3 } from './CalendarV3.ts'
import { DocsV1 } from './DocsV1.ts'
import { DriveV3 } from './DriveV3.ts'
export type { Google_Auth }
export { CalendarV3, DocsV1, DriveV3 }
