import ky, { Hooks, HTTPError, KyInstance } from "ky"

export interface Google_Auth {
	accessToken?: string
	quotaProjectId?: string
}

function gapiHooks(getAuth: () => Promise<Google_Auth | null | undefined>): Hooks {
	return {
		beforeRequest: [
			async request => {
				const auth = await getAuth()
				if (auth?.accessToken) {
					request.headers.set('authorization', `Bearer ${auth.accessToken}`)
				}
				if (auth?.quotaProjectId) {
					request.headers.set('X-Goog-User-Project', auth.quotaProjectId)
				}
			}
		]
	}
}

export function encodePathParameter(value: string) {
	return encodeURIComponent(value).replaceAll('.', '%2E')
}

function JSON_blobify(data: unknown) {
	return new Blob([JSON.stringify(data)], {type: 'application/json; charset=UTF-8'})
}

function makeMultipartRelated(parts: Blob[]) {
	const boundary = crypto.randomUUID()

	const type = `multipart/related; boundary=${boundary}`
	const body = []
	for (const part of parts) {
		body.push(`--${boundary}\r\ncontent-type: ${part.type}\r\n\r\n`)
		body.push(part)
		body.push('\r\n')
	}
	body.push(`--${boundary}--`)
	return new Blob(body, {type})
}

export type GAPI_SkipProps = 'media' | 'requestBody' | 'auth' | '$.xgafv' | 'access_token' | 'alt' | 'callback' | 'key' | 'oauth_token' | 'prettyPrint' | 'uploadType' | 'upload_protocol'

export class GoogleAPIResource {
	private _api: KyInstance
	private _upload_api?: KyInstance

	constructor(getAccessToken: () => Promise<Google_Auth | null | undefined>, urlPrefix: string, uploadUrlPrefix?: string, private defaultOptions: Record<string, string | number | boolean | undefined> = {}) {
		this._api = ky.extend({
			timeout: false,
			prefixUrl: urlPrefix,
			hooks: gapiHooks(getAccessToken),
		})
		this._upload_api = uploadUrlPrefix ? ky.extend({
			timeout: false,
			prefixUrl: uploadUrlPrefix,
			hooks: gapiHooks(getAccessToken),
		}) : undefined
	}

	async _request<T>(
		method: string,
		path: string,
		options: Record<string, string | number | boolean | undefined>,
		data?: {} | {media?: Blob} | undefined,
	): Promise<T>;
	async _request(
		method: string,
		path: string,
		options: Record<string, string | number | boolean | undefined>,
		data: {} | {media?: Blob} | undefined,
		alt: 'media',
	): Promise<Blob>;
	async _request(
		method: string,
		path: string,
		options: Record<string, string | number | boolean | undefined>,
		data?: {media?: Blob},
		alt?: 'media',
	) {
		options = {...this.defaultOptions, ...options}

		let result
		if (data && data.media) {
			if (!this._upload_api) {
				throw new Error('API does not support file upload.')
			}
			const metadata = {...data}
			delete metadata.media
			result = this._upload_api(path, {
				method,
				searchParams: {
					...options,
					uploadType: 'multipart',
				},
				body: makeMultipartRelated([JSON_blobify(metadata), data.media]),
			})
		} else {
			result = this._api(path, {
				method,
				searchParams: options as Record<string, string | number | boolean>, // TODO check if undefined is actually handled correctly
				json: data,
			})
		}
		try {
			return alt === 'media' ? await result.blob() : await result.json()
		} catch (e) {
			if (e instanceof HTTPError) {
				e.message += ` (${await e.response.text()})`
			}
			throw e
		}
	}
}
