import { AppointmentView } from "./Book.tsx"
import { GAHT } from "./GAHT/index.tsx"
import { Services } from "./Services/index.tsx"
import { Hello } from "./Hello.tsx"
import { useAppointments } from "../api.ts"

export function Home() {
	const appointments = useAppointments()

	return <main>
			<Hello/>
			{appointments && appointments.length ? <>
				<section>
					<h2>Your appointments</h2>
					{appointments.map(appointment => <AppointmentView appointment={appointment}/>)}
				</section>
				<hr data-total/>
			</> : <></>}
			<GAHT/>
			<hr data-total/>
			<Services/>
	</main>
}
