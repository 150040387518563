import { Welcome } from "./Welcome.tsx"
import { PrepareYourGAHT } from "./Prepare.tsx"
import { ReadyToGAHT } from "./Ready.tsx"
import { YourGAHT } from "./YourGAHT.tsx"
import { useHasAtLeastOneRequestedPrescription } from "./tasks/ReviewDraft.tsx"
import { useGAHTConsented, useGAHTDraftPrescription, useSubscription, useWelcomed } from "../../api.ts"

export function GAHT({fullScreen}: {fullScreen?: boolean}) {
	const welcomed = useWelcomed()!
	const subscription = useSubscription()!
	const hasConsented = useGAHTConsented()!
	const hasAtLeastOneRequestedPrescription = useHasAtLeastOneRequestedPrescription()!
	const draftPrescription = useGAHTDraftPrescription() ?? null

	const gahtOnboarding =
		(hasConsented && hasAtLeastOneRequestedPrescription && subscription.periodEnds) ? "your-gaht" :
		(draftPrescription || hasAtLeastOneRequestedPrescription) ? "ready-to-gaht" :
		welcomed ? "prepare-your-gaht" :
		"welcome"

	return gahtOnboarding === 'welcome' ? <>
		<Welcome fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'prepare-your-gaht' ? <>
		<PrepareYourGAHT fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'ready-to-gaht' ? <>
		<ReadyToGAHT fullScreen={fullScreen}/>
	</> : <>
		<YourGAHT fullScreen={fullScreen}/>
	</>
}
