import { useState } from "react"
import { Temporal } from "@js-temporal/polyfill"
import { RequestedPrescriptionInfo, useFakeSubmitGAHTBloodResults, useLastGAHTBloodResults, useSubmitGAHTBloodResults } from "../../../api.ts"
import { TherapyID } from "@imago/model"

function _TakeBloodTests() {
	const submitFiles = useSubmitGAHTBloodResults()
	const fakeSubmitFiles = useFakeSubmitGAHTBloodResults()

	const [files, setFiles] = useState<File[]>([])

	function addFiles(newFiles: File[]) {
		setFiles(files.concat(newFiles))
	}
	function removeFile(file: File) {
		setFiles(files.filter(f => f !== file))
	}

	return <form onSubmit={ev => {
		ev.stopPropagation()
		ev.preventDefault()
		submitFiles!.mutate({files})
	}} className="send-emr">
		<a href="https://www.imago.tg/faq/#blood-tests" target="_blank" role="button">See the list of required diagnostics</a>
		<p>Note: We require an original results sheet issued by the lab (PDF or photo-scan are sufficient).</p>
		{files.length
			? <ul className="files">
				{files.map(file => (
					<li key={file.name}>
						<span title={file.name}>{file.name}</span>
						<button title="Remove" onClick={ev => {
							ev.stopPropagation()
							ev.preventDefault()
							removeFile(file)
						}}>
							<span className="material-symbols-outlined">delete</span>
						</button>
					</li>
				))}
			</ul>
			: <></>
		}
		{files.length > 10 ? <p>You can upload at most 10 files. Please remove some of the above.</p> : <></>}
		<div className="row">
			{/* @ts-ignore */}
			<label role="button" disabled={submitFiles.isPending ? true : undefined}>
				Add files
				<input type="file" multiple disabled={submitFiles.isPending} onChange={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					const input = ev.target
					addFiles(Array.from(input.files!))
					input.value = ''
				}}/>
			</label>
			<button type="submit" disabled={!(files.length && files.length <= 10) || submitFiles.isPending}>Submit</button>
			{fakeSubmitFiles ?
				<button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					fakeSubmitFiles.mutate()
				}}>TEST: Skip</button>
			: <></>}
		</div>
	</form>
}

export function useIsIssuable() {
	const lastBloodResults = useLastGAHTBloodResults()
	return (prescription: RequestedPrescriptionInfo) => lastBloodResults && prescription.requestedOn && Temporal.Instant.compare(lastBloodResults, prescription.requestedOn.subtract({hours: 31*24})) > 0
}

export function useBloodTestsTaken() {
	return useIsIssuable()({
		requestedOn: Temporal.Now.instant(),
		requestersComment: "",
		requestersAddress: "",
		therapy: "" as TherapyID,
		advice: "",
		items: [],
	})
}

export function TakeBloodTests() {
	const done = useBloodTestsTaken()
	return <section data-done={done ? true : undefined}>
		<h2>Take blood tests</h2>
		{!done ? <_TakeBloodTests/> : <></>}
	</section>
}
