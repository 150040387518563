import { PrescriptionView } from "../../PrescriptionView.tsx"
import { useGAHTDraftPrescription, useGAHTPrescriptions, useRequestGAHTPrescription } from "../../../api.ts"

function _ReviewDraft() {
	const draft = useGAHTDraftPrescription()!
	const requestPrescription = useRequestGAHTPrescription()

	return <form onSubmit={ev => {
		ev.stopPropagation()
		ev.preventDefault()
		const data = new FormData(ev.currentTarget)
		requestPrescription.mutate({
			draft: draft!,
			comment: data.get('comment') as string || '',
			mailingAddress: data.get('mailingAddress') as string || '',
		})
	}}>
		{draft ? <>
			<PrescriptionView prescription={draft} full={true}/>
			<label>
				<span>
					Your comments
					{draft.basedOn ? ' / adjustment requests' : ''}
				</span>
				<textarea name="comment" rows={4}></textarea>
			</label>
			<label>
				<span>Your mailing address</span>
				<textarea name="mailingAddress" rows={3} defaultValue={draft.requestersAddress}></textarea>
			</label>
			<button type="submit" disabled={requestPrescription.isPending}>Submit</button>
		</> : <></>}
	</form>
}

export function useHasAtLeastOneRequestedPrescription() {
	const prescriptions = useGAHTPrescriptions()
	if (prescriptions === undefined) return
	return prescriptions.length > 0
}

export function useDraftReviewed() {
	const draft = useGAHTDraftPrescription()
	if (draft === undefined) return
	return draft ? false : true
}

export function ReviewDraft() {
	const done = useDraftReviewed()
	return <section data-done={done ? true : undefined}>
		<h2>Review your request</h2>
		{!done ? <_ReviewDraft/> : <></>}
	</section>
}
