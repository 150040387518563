import { useGAHTConsented, useGAHTConsent, useFakeGAHTConsent } from "../../FormDialog.tsx"
export { useGAHTConsented as useConsented }

function _Consent() {
	const consent = useGAHTConsent()!
	const fakeConsent = useFakeGAHTConsent()

	return <form onSubmit={ev => {
		ev.stopPropagation()
		ev.preventDefault()
		consent.mutate()
	}}>
		<p>The informed consent model of care respects your fundamental human right to self-determination and bodily autonomy.</p>
		<p>To start the therapy - fill out our consent form.</p>
		<div className="row">
			<button type="submit" disabled={consent.isPending}>Fill out the consent form</button>
			{fakeConsent ?
				<button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					fakeConsent.mutate()
				}} disabled={fakeConsent.isPending}>TEST: Skip</button>
			: <></>}
		</div>
	</form>
}

export function Consent() {
	const done = useGAHTConsented()
	return <section data-done={done ? true : undefined}>
		<h2>Give us your consent</h2>
		{!done ? <_Consent/> : <></>}
	</section>
}
