import { calendar_v3 } from "googleapis"
import { Google_Auth, encodePathParameter, GAPI_SkipProps, GoogleAPIResource } from "./_base.ts"

export class CalendarV3 {
	constructor(
		private getAuth: () => Promise<Google_Auth | null | undefined>,
	) {
	}

	get events() {return new CalendarV3$Events(this.getAuth)}
	get calendarList() {return new CalendarV3$CalendarList(this.getAuth)}
}

type CalendarV3$Events$SkipProps = 'calendarId' | 'eventId' | GAPI_SkipProps

class CalendarV3$Events extends GoogleAPIResource {
	constructor(
		getAuth: () => Promise<Google_Auth | null | undefined>,
	) {
		super(getAuth, 'https://www.googleapis.com/calendar/v3/')
	}

	async list(
		calendarID: string,
		options: Omit<calendar_v3.Params$Resource$Events$List, 'eventTypes' | 'privateExtendedProperty' | 'sharedExtendedProperty' | CalendarV3$Events$SkipProps> = {} // TODO add those options
	) {
		return await this._request<calendar_v3.Schema$Events>('GET', `calendars/${encodePathParameter(calendarID)}/events`, options)
	}

	async get(
		calendarID: string,
		ID: string,
		options: Omit<calendar_v3.Params$Resource$Events$Get, CalendarV3$Events$SkipProps> = {}
	) {
		return await this._request<calendar_v3.Schema$Event>('GET', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options)
	}

	async patch(
		calendarID: string,
		ID: string,
		data: Omit<calendar_v3.Schema$Event, 'id'>,
		options: Omit<calendar_v3.Params$Resource$Events$Patch, CalendarV3$Events$SkipProps> = {}) {
		return await this._request<calendar_v3.Schema$Event>('PATCH', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options, data)
	}
}

type CalendarV3$CalendarList$SkipProps = GAPI_SkipProps

class CalendarV3$CalendarList extends GoogleAPIResource {
	constructor(
		getAuth: () => Promise<Google_Auth | null | undefined>,
	) {
		super(getAuth, 'https://www.googleapis.com/calendar/v3/')
	}

	async list(
		options: Omit<calendar_v3.Params$Resource$Calendarlist$List, CalendarV3$CalendarList$SkipProps> = {}
	) {
		return await this._request<calendar_v3.Schema$CalendarList>('GET', `users/me/calendarList`, options)
	}
}
