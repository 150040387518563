import { createPopup } from "@typeform/embed"
import { EMRKindID, GAHTFHT_ID, GAHTFHTConsent_ID, GAHTIntake_ID, GAHTMHT_ID, GAHTMHTConsent_ID, Intent } from "@imago/model"
import { declareIntent, useConfig, useGAHTConsented, useGAHTIntaken, useGAHTTherapy, useIntent, useTfFile_internal } from "../api.ts"
import { createDialog, DialogProvider, RunDialogRef, useDialogWith } from "./Dialog.tsx"
import { useEffect, useState } from "react"
export { useGAHTConsented, useGAHTIntaken }

const FormDialog = createDialog<{kindID: EMRKindID}>()

export function FormDialogProvider({children}: {children: any}) {
	return <DialogProvider
		dialog={FormDialog}
		Implementation={FormDialogImplementation}
	>
		{children}
	</DialogProvider>
}

export function useGAHTIntake() {
	return useDialogWith(FormDialog, {kindID: GAHTIntake_ID})
}

function useFake(formID: EMRKindID) {
	const config = useConfig()
	const tf_file = useTfFile_internal()
	return config.testMode ? {
		mutate: async () => {
			const intent = await declareIntent({verb: 'file', formID})
			tf_file.mutate({form: intent.tf_secret!, response: '__sandbox'})
		},
		isPending: tf_file.isPending,
	} : null
}

export function useFakeGAHTIntake() {
	return useFake(GAHTIntake_ID)
}

export function useGAHTConsent() {
	const therapy = useGAHTTherapy()
	if (therapy === GAHTFHT_ID)
		return useDialogWith(FormDialog, {kindID: GAHTFHTConsent_ID})
	else if (therapy === GAHTMHT_ID)
		return useDialogWith(FormDialog, {kindID: GAHTMHTConsent_ID})
	else
		throw new Error("No therapy proposed, cannot get consent.")
}

export function useFakeGAHTConsent() {
	const therapy = useGAHTTherapy()
	if (therapy === GAHTFHT_ID)
		return useFake(GAHTFHTConsent_ID)
	else if (therapy === GAHTMHT_ID)
		return useFake(GAHTMHTConsent_ID)
	else
		throw new Error("No therapy proposed, cannot get consent.")
}

function FormDialogImplementation({runDialogRef}: {
	runDialogRef: RunDialogRef<{kindID: EMRKindID}>
}) {
	const [openDialog, setOpenDialog] = useState<{
		kindID: EMRKindID
		resolve: () => void
	}>()

	runDialogRef.current = ({kindID}: {kindID: EMRKindID}) => {
		return new Promise<void>((resolve, reject) => {
			setOpenDialog({kindID, resolve})
		})
	}

	return openDialog ? <FormDialogContent formID={openDialog.kindID} resolve={() => {
		const {resolve} = openDialog
		setOpenDialog(undefined)
		resolve()
	}}/> : <></>
}

function FormDialogContent({formID, resolve}: {
	formID: EMRKindID
	resolve: () => void
}) {
	const intent = useIntent({verb: 'file', formID})
	return intent ? <_FormDialogContent formID={formID} intent={intent} resolve={resolve}/> : <></>
}

function _FormDialogContent({formID, intent, resolve}: {
	formID: EMRKindID
	intent: Intent
	resolve: () => void
}) {
	const config = useConfig()

	const tfFormID = intent.tf_secret!

	const tf_file = useTfFile_internal()
	let resolveDone: ((v: undefined) => void) | undefined = undefined
	const done = new Promise((resolve, reject) => {
		resolveDone = resolve
	})

	useEffect(() => {
		const popup = createPopup(tfFormID, {
			enableSandbox: config.testMode,
			onSubmit: ev => tf_file.mutate({form: tfFormID, response: ev.responseId}, {
				onSettled: () => resolveDone ? resolveDone(undefined) : undefined
			}),
			onEndingButtonClick: async ev => {
				await done
				popup.close()
			},
			onClose: () => {
				resolve()
			}
		})
		popup.open()
	}, [])

	return <></>
}
