import { drive_v3 } from "googleapis"
import { Google_Auth, encodePathParameter, GAPI_SkipProps, GoogleAPIResource } from "./_base.ts"

export class DriveV3 {
	private supportsAllDrives: boolean | undefined

	constructor(
		private getAuth: () => Promise<Google_Auth | null | undefined>,
		{supportsAllDrives}: {
			supportsAllDrives?: boolean
		} = {}
	) {
		this.supportsAllDrives = supportsAllDrives
	}

	get files() {return new DriveV3$Files(this.getAuth, this.supportsAllDrives)}
}

type DriveV3$Files$SkipProps = 'fileId' | 'supportsAllDrives' | 'supportsTeamDrives' | GAPI_SkipProps

class DriveV3$Files extends GoogleAPIResource {
	constructor(
		getAuth: () => Promise<Google_Auth | null | undefined>,
		supportsAllDrives: boolean | undefined,
	) {
		super(getAuth, 'https://www.googleapis.com/drive/v3/', 'https://www.googleapis.com/upload/drive/v3/', {supportsAllDrives})
	}

	async list(
		options: Omit<drive_v3.Params$Resource$Files$List, DriveV3$Files$SkipProps> = {}
	) {
		return await this._request<drive_v3.Schema$FileList>('GET', `files`, options)
	}

	async create(
		data: Omit<drive_v3.Schema$File, 'id'> & {media?: Blob},
		options: Omit<drive_v3.Params$Resource$Files$Create, DriveV3$Files$SkipProps> = {}
	) {
		return await this._request<drive_v3.Schema$File>('POST', `files`, options, data)
	}

	async get(
		ID: string,
		options: Omit<drive_v3.Params$Resource$Files$Get, DriveV3$Files$SkipProps> = {}
	) {
		return await this._request<drive_v3.Schema$File>('GET', `files/${encodePathParameter(ID)}`, options)
	}

	async getMedia(
		ID: string,
		options: Omit<drive_v3.Params$Resource$Files$Get, DriveV3$Files$SkipProps> = {}
	) {
		return await this._request('GET', `files/${encodePathParameter(ID)}`, options, undefined, 'media')
	}

	async update(ID: string, data: Omit<drive_v3.Schema$File, 'id'> & {media?: Blob}, options: Omit<drive_v3.Params$Resource$Files$Update, DriveV3$Files$SkipProps> = {}) {
		return await this._request<drive_v3.Schema$File>('PATCH', `files/${encodePathParameter(ID)}`, options, data)
	}
}
