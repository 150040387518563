import { useContext, useEffect, useState } from "react"
import { AppointmentView, Book } from "../Book.tsx"
import { PageSetContext } from "../App.tsx"
import { WelcomeCall_ID } from "@imago/model"
import { useMigrated, useService, useServiceAppointment, useTESTSetWelcomed } from "../../api.ts"
import { Hello } from "../Hello.tsx"

export function Welcome({fullScreen}: {fullScreen?: boolean}) {
	const migrated = useMigrated()

	if (migrated === undefined) return

	return migrated === true ? <WelcomeMigrated fullScreen={fullScreen}/> : <WelcomeNative fullScreen={fullScreen}/>
}

export function WelcomeMigrated({fullScreen}: {fullScreen?: boolean}) {
	return fullScreen ? <main>
		<Hello/>
		<section>
			<h1>Welcome to the Imago app!</h1>
			<p>We’re still in the process of migrating your medical records. Meanwhile - you can use the app to manage your subscription.</p>
		</section>
	</main> : <section>
		<h2>Welcome to the Imago app!</h2>
		<p>We’re still in the process of migrating your medical records. Meanwhile - you can use the app to manage your subscription.</p>
	</section>
}

export function WelcomeNative({fullScreen}: {fullScreen?: boolean}) {
	const skip = useTESTSetWelcomed()

	const service = useService(WelcomeCall_ID)
	const appointment = useServiceAppointment(WelcomeCall_ID)
	const setPage = useContext(PageSetContext)

	const [wasNotBooked, setWasNotBooked] = useState(false)
	useEffect(() => {
		if (appointment === null)
			setWasNotBooked(true)
	}, [appointment])

	if (appointment === undefined || service === undefined) return

	return (
		appointment ? (
			fullScreen ? (
				<main>
					<Hello/>
					<section>
						{wasNotBooked ? <h1>Thanks, see you!</h1> : <></>}
						{wasNotBooked ? <p>Confirmation was sent to your email address.</p> : <></>}
						<AppointmentView appointment={appointment} service={service}/>
						<p><small>The call can only be rescheduled or cancelled at&nbsp;least&nbsp;{service.freezeTime.hours}&nbsp;hours in advance.</small></p>
						<p><small>The join button becomes active half an hour before.</small></p>
					</section>
					{skip ? <button disabled={skip.isPending} onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						skip.mutate()
					}} type="submit">TEST: Skip until after the welcome call</button> : <></>}
				</main>
			) : (
				<div style={{marginBottom: '-33px'}}/>
			)
		) : (
			fullScreen ? (
				<Book isMainScreen={true} serviceID={WelcomeCall_ID}/>
			) : (
				<section>
					<h2>Welcome to Imago!</h2>
					<p>Want to start your Gender-Affirming Hormone Therapy at Imago?</p>
					<button type="submit" onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						setPage('gaht')
					}}>Book a Welcome call</button>
				</section>
			)
		)
	)
}
