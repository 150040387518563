import { docs_v1, drive_v3 } from "googleapis"
import { DocsV1, DriveV3 } from "@imago/api-client-google-workspace"

export type DocBlockClass = 'TITLE' | 'HEADING_1' | 'HEADING_2' | 'HEADING_3' | 'HEADING_4' | 'HEADING_5' | 'HEADING_6' | 'NORMAL_TEXT'

export interface DocBlock {
	class: DocBlockClass
	text: string
}

export class SimpleDriveV1 {
	constructor(
		private drive_v3: DriveV3,
		private docs_v1: DocsV1,
		public tmpFolderID: string,
	) {}

	get files() { return new SimpleDriveV1$Files(this, this.drive_v3) }
	get folders() { return new SimpleDriveV1$Folders(this, this.drive_v3) }
	get documents() { return new SimpleDriveV1$Documents(this, this.drive_v3, this.docs_v1) }
}

class SimpleDriveV1$Files {
	constructor(
		private simpleDrive_v1: SimpleDriveV1,
		private drive_v3: DriveV3,
	) {}

	async list(folderID: string) {
		const files = await this.drive_v3.files.list({
			includeItemsFromAllDrives: true,
			pageSize: 1000,
			q: `trashed = false and '${folderID}' in parents`,
		})
		return files
	}

	async createEmpty(folderID: string, name: string) {
		return await this.drive_v3.files.create({
			name,
			parents: [folderID],
			mimeType: 'application/octet-stream',
		})
	}

	async createAndSetContent(folderID: string, name: string, content: Blob) {
		return await this.drive_v3.files.create({
			name,
			parents: [folderID],
			media: content,
		})
	}

	async rename(ID: string, name: string) {
		return await this.drive_v3.files.update(ID, {
			name
		})
	}

	async delete(ID: string) {
		return await this.drive_v3.files.update(ID, {
			trashed: true,
		})
	}
}

class SimpleDriveV1$Folders {
	constructor(
		private simpleDrive_v1: SimpleDriveV1,
		private drive_v3: DriveV3,
	) {}

	async createAndSetContent(folderID: string, name: string, content: Map<string, Blob>) {
		const file = await this.drive_v3.files.create({
			name,
			parents: [this.simpleDrive_v1.tmpFolderID],
			mimeType: "application/vnd.google-apps.folder",
		})
		await this.simpleDrive_v1.folders.setContent(file.id!, content)
		return await this.drive_v3.files.update(file.id!, {}, {
			addParents: folderID,
			removeParents: this.simpleDrive_v1.tmpFolderID,
		})
	}

	async createEmpty(folderID: string, name: string) {
		return await this.drive_v3.files.create({
			name,
			parents: [folderID],
			mimeType: 'application/vnd.google-apps.folder',
		})
	}

	async getContent(ID: string): Promise<Map<string, Blob>> {
		const folder = await this.simpleDrive_v1.files.list(ID)
		const files = await Promise.all(folder.files!.map(async file => [file.name!, await this.drive_v3.files.getMedia(file.id!)] satisfies [string, Blob]))
		return new Map(files)
	}

	async setContent(ID: string, files: Map<string, Blob>): Promise<void> {
		await Promise.all([...files].map(async ([name, blob]) => await this.drive_v3.files.create({
			name,
			parents: [ID],
			media: blob,
		})))
	}
}

class SimpleDriveV1$Documents {
	constructor(
		private simpleDrive_v1: SimpleDriveV1,
		private drive_v3: DriveV3,
		private docs_v1: DocsV1,
	) {}

	async createAndSetContent(folderID: string, name: string, content: DocBlock[]) {
		const file = await this.drive_v3.files.create({
			name,
			parents: [this.simpleDrive_v1.tmpFolderID],
			mimeType: 'application/vnd.google-apps.document',
		})
		await this.simpleDrive_v1.documents.setContent(file.id!, content)
		return await this.drive_v3.files.update(file.id!, {}, {
			addParents: folderID,
			removeParents: this.simpleDrive_v1.tmpFolderID,
		})
	}

	async createEmpty(folderID: string, name: string) {
		return await this.drive_v3.files.create({
			name,
			parents: [folderID],
			mimeType: 'application/vnd.google-apps.document',
		})
	}

	async getContent(ID: string): Promise<DocBlock[]> {
		const doc = await this.docs_v1.documents.get(ID)
		return doc.body?.content?.map(x => x.paragraph).filter(para => para !== undefined).map(para => {
			const textWithNewline = para.elements?.map(y => y.textRun?.content).filter(y => y).join('') || '\n'
			if (textWithNewline[textWithNewline.length - 1] != '\n') throw new Error('Newline expected')
			const text = textWithNewline.substring(0, textWithNewline.length - 1)
			return {
				class: para.paragraphStyle?.namedStyleType as DocBlockClass ?? 'NORMAL_TEXT',
				text: text.replaceAll('\v', '\n'),
			} satisfies DocBlock
		}) ?? []
	}

	async setContent(ID: string, blocks: DocBlock[]): Promise<drive_v3.Schema$File> {
		const doc = await this.docs_v1.documents.get(ID)

		const endIndex = (doc.body?.content?.pop()?.endIndex || 1) - 1

		const vBlocks = blocks.map(block => {return {class: block.class, text: block.text.replaceAll('\n', '\v')}})

		const requests = Array<docs_v1.Schema$Request>()

		if (endIndex > 1)
			requests.push({deleteContentRange: {range: {startIndex: 1, endIndex}}})

		if (vBlocks.length) {
			requests.push({insertText: {text: vBlocks.map(l => l.text).join('\n'), endOfSegmentLocation: {}}})
			let currentIndex = 1
			for (const line of vBlocks) {
				requests.push({updateParagraphStyle: {
					paragraphStyle: {namedStyleType: line.class},
					fields: 'namedStyleType',
					range: {startIndex: currentIndex, endIndex: currentIndex + 1},
				}})
				currentIndex += line.text.length + 1
			}
		}

		if (requests.length) {
			await this.docs_v1.documents.batchUpdate(ID, {
				requests
			})
		}

		return {
			id: doc.documentId,
			mimeType: 'application/vnd.google-apps.document',
			name: doc.title,
		}
	}
}
