import { Instant, PlainDate, TEMPORARILY_HERE_testUserTemplates as testUserTemplates } from "@imago/model"
import { useEffect, useState } from "react"
import { useSendSignInMessage, useSignInAsATestUser } from "../api.ts"
import logo from 'data-url:../logo.svg'
import { Temporal } from "@js-temporal/polyfill"
import { serialize } from "@deepkit/type"
import { PrefillProfileContent } from "./Register.tsx"

// http://localhost:1234/#typeformSignUp=true&shortName=Linus%20Test%239&pronouns=he%2Fhim&email=linus%2Bt9%40lew21.net&phone=%2B48%20664%20033%20810&dateOfBirth=1992%2F11%2F06&country=Poland

export function SignIn() {
	const [readyToRender, setReadyToRender] = useState(false)
	const [emailSent, setEmailSent] = useState<string>()

	const sendSigninMessage = useSendSignInMessage(({email}) => {
		setReadyToRender(true)
		setEmailSent(email)
	})

	useEffect(() => {
		document.documentElement.classList.add('light-only')

		// Typeform-initiated sign-up
		const hashParams = new URLSearchParams(document.location.hash.substring(1))
		const typeformSignUp = hashParams.get('typeformSignUp') === 'true'
		if (typeformSignUp)
			console.info('typeform sign-up referrer', document.referrer)
		const shortName = hashParams.get('shortName')
		const pronouns = hashParams.get('pronouns')
		const email = hashParams.get('email')
		const phone = hashParams.get('phone')
		const dateOfBirth = hashParams.get('dateOfBirth')
		const country = hashParams.get('country')
		if (typeformSignUp && email) {
			localStorage.setItem('prefill-profile', JSON.stringify(serialize<PrefillProfileContent>({
				timestamp: new Instant(Temporal.Now.instant().epochNanoseconds),
				shortName: shortName ?? undefined,
				pronouns: pronouns ?? undefined,
				dateOfBirth: dateOfBirth ? PlainDate.from(dateOfBirth.replaceAll('/', '-')) : undefined,
			})))
			sendSigninMessage.mutate({email})
			const url = new URL(document.location.href)
			url.search = ''
			url.hash = ''
			if (document.location.href != url.toString()) {
				window.history.replaceState(null, '', url)
			}
		} else {
			setReadyToRender(true)
		}
	}, [])

	return readyToRender ? <div className="sign-in">
		<main>
			<div>
				{!emailSent
					? <form onSubmit={async ev => {
							ev.preventDefault()
							ev.stopPropagation()
							const formData = new FormData(ev.currentTarget)
							const email = formData.get('email') as string
							sendSigninMessage.mutate({email})
						}} style={{alignItems: 'center', gap: '32px'}}>
						<img alt="Imago" src={logo} style={{width: '50%'}}/>
						<label style={{width: '80%'}}>
							<span>Your e-mail</span>
							<input name="email" type="email" autoFocus required/>
						</label>
						<button type="submit" disabled={sendSigninMessage.isPending}>Continue</button>
					</form>
					: <div>
						<h2>Check your e-mail</h2>
						<p>We've sent you (<strong>{emailSent}</strong>) a sign-in link.</p>
					</div>
				}
			</div>
		</main>
	</div> : <></>
}

function TestAccounts() {
	const signIn = useSignInAsATestUser()

	if (!signIn) return

	return <aside className="dev">
		<h3 style={{textAlign: "center"}}>Test accounts</h3>
		{testUserTemplates.map(template =>
			<button onClick={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				signIn.mutate({templateID: template.ID})
			}} key={template.ID} disabled={signIn.isPending}>Sign in as {template.shortName} - {template.description}</button>
		)}
	</aside>
}
