import { AppointmentView, useBook } from "../../Book.tsx"
import { useService, useServiceAppointment } from "../../../api.ts"
import { GAHTSetupCall_ID, GAHTTransferCall_ID } from "@imago/model"

function BookACall() {
	const book = useBook()!

	return <>
		<button type="submit" onClick={ev => {
			ev.stopPropagation()
			ev.preventDefault()
			book.mutate({serviceID: GAHTSetupCall_ID})
		}}>I want to start GAHT</button>
		<button type="submit" onClick={ev => {
			ev.stopPropagation()
			ev.preventDefault()
			book.mutate({serviceID: GAHTTransferCall_ID})
		}}>I’m already on GAHT, I want to move to Imago</button>
	</>
}

export function TalkWithAPhysician({allowed}: {allowed: boolean}) {
	const setupAppointment = useServiceAppointment(GAHTSetupCall_ID)
	const transferAppointment = useServiceAppointment(GAHTTransferCall_ID)
	const appointment = (setupAppointment === undefined || transferAppointment === undefined) ? undefined : (setupAppointment ?? transferAppointment)
	const service = useService(appointment?.serviceID)

	return <section>
		<h2>Talk with our physician</h2>
		<p>Our physician will tell you everything you need to know about the Gender-Affirming Hormone Therapy, and will answer your questions. They’ll also review your medical history and blood tests, to ensure that the therapy is safe and efficient.</p>
		{appointment ? <>
			<AppointmentView appointment={appointment} service={service}/>
			{service ? <p><small>The call can only be rescheduled or cancelled at&nbsp;least&nbsp;{service.freezeTime.hours}&nbsp;hours in advance.</small></p> : <></>}
			<p><small>The join button becomes active half an hour before.</small></p>
		</> : allowed ? <>
			<BookACall/>
		</> : <p><strong>To book a call - please finish the above steps first.</strong></p>}
	</section>
}
