import { URIObject, KyInstance, KyResponse } from '@imago/api-client'

const YCBM_AccountID_Brand: unique symbol = Symbol()
export type YCBM_AccountID = string & {[YCBM_AccountID_Brand]: never}

const YCBM_Subdomain_Brand: unique symbol = Symbol()
export type YCBM_Subdomain = string & {[YCBM_Subdomain_Brand]: never}

const YCBM_ProfileID_Brand: unique symbol = Symbol()
export type YCBM_ProfileID = string & {[YCBM_ProfileID_Brand]: never}

const YCBM_RefRaw_Brand: unique symbol = Symbol()
export type YCBM_RefRaw = string & {[YCBM_RefRaw_Brand]: never}

const YCBM_RefFormatted_Brand: unique symbol = Symbol()
export type YCBM_RefFormatted = string & {[YCBM_RefFormatted_Brand]: never}

const YCBM_IntentSecret__Brand: unique symbol = Symbol()
export type YCBM_IntentSecret = string & {[YCBM_IntentSecret__Brand]: never}

declare const YCBM_AvailabilityKey__Brand: unique symbol;
export type YCBM_AvailabilityKey = string & {[YCBM_AvailabilityKey__Brand]: never}

export interface YCBM_ProfileInfo {
	id: YCBM_ProfileID
	locale: string
	logo: string
	subdomain: YCBM_Subdomain
	teamsActive: boolean
	title: string
	createdAt: string
	updatedAt: string
}

export type YCBM_ProfilesResponse = YCBM_ProfileInfo[]

interface YCBM_Intents_Create {
	subdomain: YCBM_Subdomain,
	selections: {
		form: {
			id: string
			value: string
		}[]
	}
}

export interface YCBM_IntentResponse {
	id: YCBM_IntentSecret
	bookingId: null // | YCBM_BookingID
	selections: {
		startsAt: number
		timeZone: string
	}
}

export interface YCBM_IntentAvailabilityKeyResponse {
	key: YCBM_AvailabilityKey
}

export interface YCBM_AvailabilitiesResponse {
	slots: {
		freeUnits: number
		startsAt: string
	}[]
}

export interface YCBM_Answer {
	code: string
	string: string
}

export interface YCBM_BookingInfo {
	cancelled: boolean
	profileId: YCBM_ProfileID
	startsAtUTC: string
	endsAtUTC: string
	timeZone: string
	durationMinutes: number
	ref: YCBM_RefRaw
	intentId: YCBM_IntentSecret
	answers: YCBM_Answer[]
	noShow?: boolean
	notes?: string
}

export type YCBM_BookingsResponse = YCBM_BookingInfo[]

export function YCBM_formatRef(ref: YCBM_RefRaw): YCBM_RefFormatted {
	if (ref.length != 12) {
		throw new Error()
	}
	return `${ref.substring(0, 4)}-${ref.substring(4, 8)}-${ref.substring(8, 12)}` as YCBM_RefFormatted
}

export class YCBM {
	private _remote: _YCBM_Remote

	constructor(uri: URIObject) {
		this._remote = new _YCBM_Remote(uri.api, uri.username! as YCBM_AccountID)
	}

	get profiles() {return new YCBM_Profiles(this._remote)}
	get intents() {return new YCBM_Intents(this._remote)}
	get bookings() {return new YCBM_Bookings(this._remote)}
}

class _YCBM_Remote {
	constructor(
		public api: KyInstance,
		public accountID: YCBM_AccountID,
	) {}
}

export class YCBM_Profiles {
	constructor(private _remote: _YCBM_Remote) {}

	async list() {
		const profiles = new Array<YCBM_ProfileInfo>()
		const data = await this._remote.api.get(`v1/profiles?ownerId=${this._remote.accountID}`).json() as YCBM_ProfilesResponse
		profiles.push(...data)
		return profiles
	}
}

export class YCBM_Intents {
	constructor(private _remote: _YCBM_Remote) {}

	async create(params: YCBM_Intents_Create) {
		const data = await this._remote.api.post(`v1/intents`, {json: params}).json() as YCBM_IntentResponse
		return data
	}
}

export class YCBM_Bookings {
	constructor(private _remote: _YCBM_Remote) {}

	async list({search}: {search?: string} = {}) {
		const bookings = new Array<YCBM_BookingInfo>()
		let resp: KyResponse | null = await this._remote.api.get(`v1/${this._remote.accountID}/bookings`, {
			searchParams: {
				...(search !== undefined ? {search} : {}),
				from: '2000-01-01T00:00:00Z',
				direction: 'forwards',
				fields: '*,answers.code,answers.string',
			},
		})
		let data = await resp.json() as YCBM_BookingsResponse
		while (resp && data.length) {
			bookings.push(...data)
			console.debug(bookings.length)
			const links = parseLinkHeaders(resp.headers.get('link') ?? '')
			const next = links.get('next')
			if (next) {
				resp = await this._remote.api.get(next, {prefixUrl: ''})
				data = await resp.json() as YCBM_BookingsResponse
			} else {
				resp = null
				data = []
			}
		}
		return bookings
	}
}

function parseLinkHeaders(linkHeaders: string) {
	return new Map(linkHeaders.split(',').map(linkHeader => {
		const segments = linkHeader.split(';').map(seg => seg.trim())
		const rawUri = segments.shift()!
		if (rawUri[0] !== '<' || rawUri[rawUri.length - 1] !== '>') {
			throw new Error('Invalid link header')
		}
		const uri = decodeURIComponent(rawUri.substring(1, rawUri.length - 1))
		const params = new Map(segments.map(p => {
			const eqPos = indexOf(p, '=')
			const key = p.substring(0, eqPos ?? undefined).trim()
			const rawValue = eqPos ? p.substring(eqPos + 1).trim() : ''
			const value = rawValue[0] === '"' ? rawValue.substring(1, rawValue.length - 1) : rawValue
			return [
				key,
				value,
			]
		}))
		return [
			params.get('rel') ?? '',
			uri,
		]
	}))
}

function indexOf(haystack: string, s: string) {
	const pos = haystack.indexOf(s)
	if (pos === -1) return null
	return pos
}
